import { Status, ConsumerStatus, TokenRequestorType, RequestorType } from '@/helpers/constants'
import { DateTime } from 'luxon'

const utils = {
  randomString,
  getTokenType,
  getTokenFormatType,
  getRequestorType,
  getStatusName,
  getStatusBadge,
  getConsumerStatusName,
  getConsumerStatusBadge,
  getTokenRequestorName,
  ccFormat,
  dateFormat,
  toDateTimeObj,
  filterToDateTimeObj,
  snakeToCamel,
  capitalize,
  getStatusOption,
  getConsumerStatusOption,
  getTaskStatusOption,
  getSettingNameOption,
  getPanStatusOption,
  getTokenEventOption,
  getCardEventOption,
  getCompanyTypeOption,
  getSeverityBadge,
  getSeverityName,
  getTaskStatusName,
  getHostType,
  getHostTypeOption,
  getUserType,
  getUserTypeOption,
  numberWithCommas,
  getTestCaseColor,
  getTestCaseStatus,
  getCardSchemeLabel,
  getTokenStatusBadge,
  getTokenStatusName,
  getApiKeyTypeName,
  getTokenRequestorTypeOption,
  getConsentType,
  getConsentTypeOption,
  getApplicationType,
  getApplicationTypeOption,
  getIdentityType,
  getIdentityTypeOption,
  isTrue,
  getCardPendingEvent,
  getAddressTypeOption,
  getAddressType,
  getCardEventType,
  getCardEventReasonType,
  getCardEventRequestorType,
  getCardEventRequestorTypeOption,
  getCardEventTypeOption,
  getCardEventReasonTypeOption,
  getCheckoutEventType,
  getCheckoutEventTypeOption,
  getCheckoutEventStatus,
  getCheckoutEventStatusOption,
  getConfirmationStatus,
  getConfirmationStatusOption,
  getBillingShippingRelationship,
  getTransactionType,
  getTransactionTypeOption,
  getAcceptanceChannelType,
  getAcceptanceChannelTechnology,
  getAddressPreference,
  get3dsPreference,
  getPayloadTypeIndicator,
  getCardStatusBadge,
  getCardStatusName,
  getClientTypeOption,
  getClientType,
  getCardSource
}

function snakeToCamel (str) {
  return str.replace(/([-_]\w)/g, g => g[1].toUpperCase())
}

function randomString (length) {
  const chars = '0123456789abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ'
  var result = ''
  for (var i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

function getCompanyTypeOption () {
  return [
    { label: 'Sole proprietorship', value: 'Sole proprietorship' },
    { label: 'Partnership', value: 'Partnership' },
    { label: 'Limited Liability Partnership (LLP)', value: 'Limited Liability Partnership (LLP)' },
    { label: 'Private Limited Company (Sdn. Bhd.)', value: 'Private Limited Company (Sdn. Bhd.)' },
    { label: 'Public Limited Company (Bhd)', value: 'Public Limited Company (Bhd)' }
  ]
}

function getStatusOption () {
  return [
    { label: 'Active', value: Status.ACTIVE },
    { label: 'Suspended', value: Status.SUSPENDED }
  ]
}

function getConsumerStatusOption () {
  return [
    { label: 'Active', value: ConsumerStatus.ACTIVE },
    { label: 'Locked', value: ConsumerStatus.LOCKED },
    { label: 'Suspended', value: ConsumerStatus.SUSPENDED }
  ]
}

function getPanStatusOption () {
  return [
    { label: 'Active', value: '00' },
    { label: 'Suspended', value: '01' },
    { label: 'Expired', value: '02' },
    { label: 'Pending', value: '03' },
    { label: 'Cancelled', value: '04' }
  ]
}

function getTaskStatusOption () {
  return [
    { label: 'Pending', value: '00' },
    { label: 'Approved', value: '01' },
    { label: 'Rejected', value: '02' },
    { label: 'Deleted', value: '03' },
    { label: 'Expired', value: '04' }
  ]
}

function getSettingNameOption () {
  return [
    { label: 'Address', value: 'Address' },
    { label: 'BIN', value: 'BIN' },
    { label: 'Card', value: 'Card' },
    { label: 'Client', value: 'Client' },
    { label: 'Consumer', value: 'Consumer' },
    { label: 'DCF', value: 'DCF' },
    { label: 'Device', value: 'Device' },
    { label: 'DPA', value: 'DPA' },
    { label: 'Legal', value: 'Legal' },
    { label: 'Merchant', value: 'Merchant' },
    { label: 'Profile', value: 'Profile' },
    { label: 'Role', value: 'Role' },
    { label: 'User', value: 'User' },
    { label: 'System', value: 'System' }
  ]
}
function getTokenEventOption () {
  return [
    { label: 'PROVISION', value: 'PROVISION' },
    { label: 'TOKEN_DATA', value: 'TOKEN_DATA' },
    { label: 'DE_TOKEN', value: 'DE_TOKEN' },
    { label: 'TOKEN_VERIFY', value: 'TOKEN_VERIFY' },
    { label: 'TOKEN_SUSPEND', value: 'TOKEN_SUSPEND' },
    { label: 'TOKEN_RESUME', value: 'TOKEN_RESUME' },
    { label: 'TOKEN_DELETE', value: 'TOKEN_DELETE' },
    { label: 'TOKEN_STATUS', value: 'TOKEN_STATUS' }
  ]
}

function getCardEventOption () {
  return [
    { label: 'CARD_METADATA', value: 'CARD_METADATA' },
    { label: 'CARD_SUSPEND', value: 'CARD_SUSPEND' },
    { label: 'CARD_RESUME', value: 'CARD_RESUME' },
    { label: 'CARD_DELETE', value: 'CARD_DELETE' },
    { label: 'CARD_PAN_UPDATE', value: 'CARD_PAN_UPDATE' },
    { label: 'CARD_EXPIRY_UPDATE', value: 'CARD_EXPIRY_UPDATE' }
  ]
}

function getStatusName (status) {
  return status === Status.ACTIVE ? 'Active'
    : status === Status.SUSPENDED ? 'Suspended'
      : status === Status.DELETED ? 'Deleted'
        : status === Status.EXPIRED ? 'Expired' : 'Unknown'
}

function getStatusBadge (status) {
  return status === Status.ACTIVE ? 'success'
    : status === Status.SUSPENDED ? 'warning'
      : status === Status.DELETED ? 'danger'
        : status === Status.EXPIRED ? 'warning' : 'primary'
}

function getConsumerStatusName (status) {
  return status === ConsumerStatus.ACTIVE ? 'Active'
    : status === ConsumerStatus.SUSPENDED ? 'Suspended'
      : status === ConsumerStatus.DELETED ? 'Deleted'
        : status === ConsumerStatus.LOCKED ? 'Locked' : 'Unknown'
}

function getConsumerStatusBadge (status) {
  return status === ConsumerStatus.ACTIVE ? 'success'
    : status === ConsumerStatus.SUSPENDED ? 'warning'
      : status === ConsumerStatus.DELETED ? 'danger'
        : status === ConsumerStatus.LOCKED ? 'warning' : 'primary'
}

function getTokenStatusName (status, cardStatus) {
  if ((status === Status.ACTIVE || status === Status.SUSPENDED) && cardStatus === Status.SUSPENDED) {
    return 'Suspended By Issuer'
  }
  return status === Status.ACTIVE ? 'Active'
    : status === Status.SUSPENDED ? 'Suspended'
      : status === Status.DELETED ? 'Deleted'
        : status === Status.EXPIRED ? 'Expired' : 'Unknown'
}

function getTokenStatusBadge (status, cardStatus) {
  if ((status === Status.ACTIVE || status === Status.SUSPENDED) && cardStatus === Status.SUSPENDED) {
    return 'warning'
  }

  return status === Status.ACTIVE ? 'success'
    : status === Status.SUSPENDED ? 'warning'
      : status === Status.DELETED ? 'danger'
        : status === Status.EXPIRED ? 'warning' : 'primary'
}

function getTokenRequestorName (type) {
  return type === TokenRequestorType.PAYMENT_GATEWAY ? 'Payment Gateway'
    : type === TokenRequestorType.MERCHANT ? 'Merchant'
      : type === TokenRequestorType.WALLET_PROVIDER ? 'Wallet Provider' : 'Unknown'
}

function getTokenType (type) {
  return type === '00' ? 'Static'
    : type === '01' ? 'Guest Checkout' : 'FRU'
}

function getTokenFormatType (type) {
  return type === '00' ? 'ISO/IEC 7812' : 'FRU'
}

function getRequestorType (type) {
  return type === RequestorType.MERCHANT ? 'Merchant'
    : type === RequestorType.ISSUER ? 'Issuer'
      : type === RequestorType.DS ? 'DS'
        : type === RequestorType.SWITCH ? 'Switch'
          : type === RequestorType.PORTAL ? 'Portal' : 'Unknown'
}

function getSeverityBadge (severity) {
  return severity === '00' ? 'secondary'
    : severity === '01' ? 'warning'
      : severity === '02' ? 'danger' : 'danger'
}

function getSeverityName (severity) {
  return severity === '00' ? 'Low'
    : severity === '01' ? 'Medium'
      : severity === '02' ? 'High' : 'Unknown'
}

function getTaskStatusName (status) {
  return status === '00' ? 'Pending'
    : status === '01' ? 'Approved'
      : status === '02' ? 'Rejected'
        : status === '03' ? 'Withdrawn'
          : status === '04' ? 'Expired' : 'Unknown'
}

function ccFormat (value) {
  if (value == null) {
    return ''
  }

  var parts = []
  for (var i = 0, len = value.length; i < len; i += 4) {
    parts.push(value.substring(i, i + 4))
  }
  if (parts.length) {
    return parts.join(' ')
  } else {
    return value
  }
}

function dateFormat (dateVal, timeVal, sec) {
  var d
  var t
  if (dateVal) {
    d = DateTime.fromISO(dateVal).toFormat('y-MM-dd')
  } else {
    return ''
  }

  if (timeVal) {
    t = DateTime.fromISO(timeVal).toFormat('HH:mm:' + sec)

    return d + ' ' + t
  } else {
    return d + ' 00:00:' + sec
  }
}

// Convert from backend datetime / date string format to DateTime object
function toDateTimeObj (datetimeStr) {
  return DateTime.fromFormat(datetimeStr, 'dd/MM/yyyy HH:mm:ss')
}

function filterToDateTimeObj (datetimeStr) {
  return DateTime.fromFormat(datetimeStr, 'yyyy-MM-dd HH:mm:ss')
}

function capitalize (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

function getHostType (type) {
  return type === '00' ? 'Switch'
    : type === '01' ? 'DS' : 'Unknown'
}

function getHostTypeOption () {
  return [
    { label: 'Switch', value: '00' },
    { label: 'DS', value: '01' }
  ]
}

function getUserType (type) {
  return type === '00' ? 'Paynet Internal User'
    : type === '01' ? 'Issuer User'
      : type === '02' ? 'SRCI User' : 'Unknown'
}

function getTestCaseStatus (value) {
  return value === '00' ? 'Open'
    : value === '01' ? 'Passed'
      : value === '02' ? 'Failed'
        : value === '03' ? 'Pending' : 'Unknown'
}

function getTestCaseColor (value) {
  return value === '00' ? 'secondary'
    : value === '01' ? 'success'
      : value === '02' ? 'danger'
        : value === '03' ? 'dark' : 'danger'
}

function getUserTypeOption () {
  return [
    { label: 'Paynet Internal User', value: '00' },
    { label: 'SRCI User', value: '02' }
  ]
}

function numberWithCommas (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function getCardSchemeLabel (value) {
  return value === 'MCCS' ? 'MyDebit'
    : value === 'VISA' ? 'Visa'
      : value === 'MC' ? 'Mastercard' : 'Unknown'
}

function getApiKeyTypeName (value) {
  return value === '00' ? 'Authentication'
    : value === '01' ? 'Notification'
      : value === '02' ? 'Encryption' : 'Unknown'
}

function getTokenRequestorTypeOption () {
  return [
    { label: 'Payment Gateway', value: '00' },
    { label: 'Merchant', value: '01' },
    { label: 'Wallet Provider', value: '02' }
  ]
}

function getConsentType (value) {
  return value === '00' ? 'Privacy Policy'
    : value === '01' ? 'Terms & Conditions'
      : value === '02' ? 'Cookie Policy'
        : value === '03' ? 'Geolocation Policy' : 'Unknown'
}

function getConsentTypeOption () {
  return [
    { label: 'Privacy Policy', value: '00' },
    { label: 'Terms & Conditions', value: '01' },
    { label: 'Cookie Policy', value: '02' },
    { label: 'Geolocation Policy', value: '03' }
  ]
}

function getApplicationType (value) {
  return value === '00' ? 'Web Browser'
    : value === '01' ? 'Mobile App'
      : value === '02' ? 'IoT Device'
        : value === '03' ? 'Other' : 'Unknown'
}

function getApplicationTypeOption () {
  return [
    { label: 'Web Browser', value: '00' },
    { label: 'Mobile App', value: '01' },
    { label: 'IoT Device', value: '02' },
    { label: 'Other', value: '03' }
  ]
}

function getIdentityType (value) {
  return value === '00' ? 'Email Address'
    : value === '01' ? 'Mobile Phone' : 'Unknown'
}

function getIdentityTypeOption () {
  return [
    { label: 'Email Address', value: '00' },
    { label: 'Mobile Phone', value: '01' }
  ]
}

function isTrue (val) {
  if (val == null) {
    return ''
  } else if (val == true) {
    return 'Yes'
  } else {
    return 'No'
  }
}

function getCardPendingEvent (value) {
  return value === '00' ? 'PENDING_AVS'
    : value === '01' ? 'PENDING_SCA'
      : value === '02' ? 'PENDING_CONSUMER_IDV' : ''
}

function getAddressType (value) {
  return value === '00' ? 'Billing'
    : value === '01' ? 'Shipping' : ''
}

function getAddressTypeOption () {
  return [
    { label: 'Billing', value: '00' },
    { label: 'Shipping', value: '01' }
  ]
}

function getCardEventType (value) {
  return value === '00' ? 'Modified'
    : value === '01' ? 'Deleted'
      : value === '02' ? 'Suspend'
        : value === '03' ? 'Unsuspend' : 'Unknown'
}

function getCardEventReasonType (value) {
  return value === '00' ? 'Suspected Fraud'
    : value === '01' ? 'Account Closed' : 'Unknown'
}

function getCardEventRequestorType (value) {
  return value === '00' ? 'Cardholder'
    : value === '01' ? 'Merchant'
      : value === '02' ? 'Issuer' : 'Unknown'
}

function getCardEventTypeOption () {
  return [
    { label: 'Modified', value: '00' },
    { label: 'Deleted', value: '01' },
    { label: 'Suspend', value: '02' },
    { label: 'Unsuspend', value: '03' }
  ]
}

function getCardEventReasonTypeOption () {
  return [
    { label: 'Suspected Fraud', value: '00' },
    { label: 'Account Closed', value: '01' }
  ]
}

function getCardEventRequestorTypeOption () {
  return [
    { label: 'Cardholder', value: '00' },
    { label: 'Merchant', value: '01' },
    { label: 'Issuer', value: '02' }
  ]
}

function getCheckoutEventType (value) {
  switch (value) {
    case '00':
      return 'Place Order'
    case '01':
      return 'Authorise'
    case '02':
      return 'Capture'
    case '03':
      return 'Refund'
    case '04':
      return 'Cancel (Auth Reversal)'
    case '05':
      return 'Fraud'
    case '06':
      return 'Chargeback'
    case '07':
      return 'Cancel Before Auth'
    case '08':
      return 'Auth For Account'
    default:
      return ''
  }
}

function getCheckoutEventTypeOption () {
  return [
    { label: 'Place Order', value: '00' },
    { label: 'Authorise', value: '01' },
    { label: 'Capture', value: '02' },
    { label: 'Refund', value: '03' },
    { label: 'Cancel (Auth Reversal)', value: '04' },
    { label: 'Fraud', value: '05' },
    { label: 'Chargeback', value: '06' },
    { label: 'Cancel Before Auth', value: '07' },
    { label: 'Auth For Account', value: '08' }
  ]
}

function getCheckoutEventStatus (value) {
  switch (value) {
    case '01':
      return 'Created'
    case '02':
      return 'Confirmed'
    case '03':
      return 'Cancelled'
    case '04':
      return 'Fraud Cancelled'
    case '05':
      return 'Others'
    default:
      return 'Others'
  }
}

function getCheckoutEventStatusOption () {
  return [
    { label: 'Created', value: '01' },
    { label: 'Confirmed', value: '02' },
    { label: 'Cancelled', value: '03' },
    { label: 'Fraud Cancelled', value: '04' },
    { label: 'Others', value: '05' }
  ]
}

function getConfirmationStatus (value) {
  switch (value) {
    case '01':
      return 'Success'
    case '02':
      return 'Failure'
    case '03':
      return 'Other'
    case '04':
      return 'Timeout'
    default:
      return 'Unknown'
  }
}
function getConfirmationStatusOption () {
  return [
    { label: 'Success', value: '01' },
    { label: 'Failure', value: '02' },
    { label: 'Other', value: '03' },
    { label: 'Timeout', value: '04' }
  ]
}

function getBillingShippingRelationship (value) {
  return value === '01' ? 'Same as Cardholder’s billing address'
    : value === '02' ? 'Consumer\'s preferred shipping address'
      : value === '03' ? 'Consumer other address' : 'Unknown'
}

function getTransactionType (value) {
  return value === '00' ? 'Purchase'
    : value === '01' ? 'Bill Payment'
      : value === '02' ? 'Money Transfer'
        : value === '03' ? 'Disbursement'
          : value === '04' ? 'P2P' : 'Unknown'
}

function getTransactionTypeOption () {
  return [
    { label: 'Purchase', value: '00' },
    { label: 'Bill Payment', value: '01' },
    { label: 'Money Transfer', value: '02' },
    { label: 'Disbursement', value: '03' },
    { label: 'P2P', value: '04' }
  ]
}

function getAcceptanceChannelType (value) {
  return value === '00' ? 'EMV MERCHANT PRESENTED MODE' : 'Unknown'
}

function getAcceptanceChannelTechnology (value) {
  return value === '00' ? 'QR CODE' : 'Unknown'
}
function getAddressPreference (value) {
  return value === '00' ? 'None'
    : value === '01' ? 'Full'
      : value === '02' ? 'Postal Country' : 'Unknown'
}
function get3dsPreference (value) {
  return value === '00' ? 'None'
    : value === '01' ? 'Self'
      : value === '02' ? 'Onbehalf' : 'Unknown'
}

function getPayloadTypeIndicator (value) {
  return value === '00' ? 'Summary'
    : value === '01' ? 'Full'
      : value === '02' ? 'Payment'
        : value === '03' ? 'Non Payment' : 'Unknown'
}

function getCardStatusName (status) {
  switch (status) {
    case '00':
      return 'Active'
    case '01':
      return 'Suspended'
    case '02':
      return 'Expired'
    case '03':
      return 'Pending'
    case '04':
      return 'Cancelled'
    case '05':
      return 'Deleted'
    default:
      return 'Unknown'
  }
}

function getCardStatusBadge (status) {
  switch (status) {
    case '00':
      return 'success'
    case '01':
      return 'warning'
    case '02':
      return 'secondary'
    case '03':
      return 'secondary'
    case '04':
      return 'dark'
    case '05':
      return 'danger'
    default:
      return 'primary'
  }
}

function getClientTypeOption () {
  return [
    { label: 'SRCI', value: '00' }
    // { label: 'DCF', value: '01' },
    // { label: 'SRCPI', value: '02' }
  ]
}

function getClientType (value) {
  return value === '00' ? 'SRCI'
    : value === '01' ? 'DCF'
      : value === '02' ? 'SRCPI' : 'Unknown'
}

function getCardSource (value) {
  return value === '00' ? 'Cardholder'
    : value === '01' ? 'Merchant'
      : value === '02' ? 'Issuer' : ''
}
export default utils
