import Vue from 'vue'
import VueRouter from 'vue-router'
import Container from '@/views/layouts/MainContainer.vue'

Vue.use(VueRouter)

function lazyLoad (view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    component: Container,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: lazyLoad('MainDashboard')
      },
      {
        path: 'profiles',
        name: 'Profile Management',
        redirect: '/profiles',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/profiles',
            name: 'Consumer Profiles',
            meta: {permission: 'profile_view'},
            component: lazyLoad('profiles/ProfileIndex')
          },
          {
            path: '/profile/:id/edit',
            name: 'Edit Consumer Profile',
            meta: {permission: 'profile_update'},
            component: lazyLoad('consumers/ConsumerEdit')
          },
          {
            path: '/profile/:id',
            name: 'Profile Detail',
            meta: {permission: 'profile_view'},
            component: lazyLoad('profiles/ProfileDetail')
          },
          {
            path: '/addresses',
            name: 'Addresses',
            meta: {permission: 'profile_view'},
            component: lazyLoad('addresses/AddressIndex')
          },
          {
            path: '/address/:id',
            name: 'Address Detail',
            meta: {permission: 'profile_view'},
            component: lazyLoad('addresses/AddressDetail')
          },
          {
            path: '/address/:id/edit',
            name: 'Edit Address',
            meta: {permission: 'profile_update'},
            component: lazyLoad('addresses/AddressEdit')
          },
          {
            path: '/devices',
            name: 'Trusted Devices',
            meta: {permission: 'profile_view'},
            component: lazyLoad('devices/DeviceIndex')
          },
          {
            path: '/device/:id',
            name: 'Trusted Device Detail',
            meta: {permission: 'profile_view'},
            component: lazyLoad('devices/DeviceDetail')
          },
          {
            path: '/cards',
            name: 'Payment Cards',
            meta: {permission: 'profile_view'},
            component: lazyLoad('cards/CardIndex')
          },
          {
            path: '/card/:id',
            name: 'Payment Card Detail',
            meta: {permission: 'profile_view'},
            component: lazyLoad('cards/CardDetail')
          },
          {
            path: '/card/:id/edit',
            name: 'Edit Payment Card',
            meta: {permission: 'profile_update'},
            component: lazyLoad('cards/CardEdit')
          },
          {
            path: '/cards/events',
            name: 'Card Events',
            meta: {permission: 'profile_view'},
            component: lazyLoad('cards/CardEvents')
          }
        ]
      },
      {
        path: 'checkouts',
        name: 'Checkout Management',
        redirect: '/checkouts',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/checkouts',
            name: 'Checkouts',
            meta: {permission: 'checkout_view'},
            component: lazyLoad('checkouts/CheckoutIndex')
          },
          {
            path: '/checkout/:id',
            name: 'Checkout Detail',
            meta: {permission: 'checkout_view'},
            component: lazyLoad('checkouts/CheckoutDetail')
          },
          {
            path: '/checkouts/events',
            name: 'Checkout Events',
            meta: {permission: 'checkout_event_view'},
            component: lazyLoad('checkouts/CheckoutEvents')
          },
          {
            path: '/checkouts/event/:id',
            name: 'Checkout Event Detail',
            meta: {permission: 'checkout_event_view'},
            component: lazyLoad('checkouts/EventDetail')
          }
        ]
      },
      {
        path: 'dcfs',
        name: 'DCF Management',
        redirect: '/dcfs',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/dcfs',
            name: 'Digital Card Facilitators',
            meta: {permission: 'dcf_view'},
            component: lazyLoad('dcfs/DcfIndex')
          },
          {
            path: '/dcf/new',
            name: 'Add New DCF',
            meta: {permission: 'dcf_create'},
            component: lazyLoad('dcfs/DcfCreate')
          },
          {
            path: '/dcf/:id/edit',
            name: 'Edit DCF',
            meta: {permission: 'dcf_update'},
            component: lazyLoad('dcfs/DcfEdit')
          },
          {
            path: '/dcf/:id',
            name: 'DCF Detail',
            meta: {permission: 'dcf_view'},
            component: lazyLoad('dcfs/DcfDetail')
          }
        ]
      },
      {
        path: 'dpas',
        name: 'DPA Management',
        redirect: '/dpas',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/dpas',
            name: 'Digital Payment Applications',
            meta: {permission: 'dpa_view'},
            component: lazyLoad('dpas/DpaIndex')
          },
          {
            path: '/dpa/new',
            name: 'Add New DPA',
            meta: {permission: 'dpa_create'},
            component: lazyLoad('dpas/DpaCreate')
          },
          {
            path: '/dpa/:id/edit',
            name: 'Edit DPA',
            meta: {permission: 'dpa_update'},
            component: lazyLoad('dpas/DpaEdit')
          },
          {
            path: '/dpa/:id',
            name: 'DPA Detail',
            meta: {permission: 'dpa_view'},
            component: lazyLoad('dpas/DpaDetail')
          }
        ]
      },
      {
        path: 'merchants',
        name: 'Merchant Management',
        redirect: '/merchants',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/merchants',
            name: 'Merchants',
            meta: {permission: 'merchant_view'},
            component: lazyLoad('merchants/MerchantIndex')
          },
          {
            path: '/merchant/new',
            name: 'Add New Merchant',
            meta: {permission: 'merchant_create'},
            component: lazyLoad('merchants/MerchantCreate')
          },
          {
            path: '/merchant/:id/edit',
            name: 'Edit Merchant',
            meta: {permission: 'merchant_update'},
            component: lazyLoad('merchants/MerchantEdit')
          },
          {
            path: '/merchant/:id',
            name: 'Merchant Detail',
            meta: {permission: 'merchant_view'},
            component: lazyLoad('merchants/MerchantDetail')
          }
        ]
      },
      {
        path: '/client/me',
        name: 'SRC Client Profile',
        meta: {permission: 'client_profile_view'},
        component: lazyLoad('clients/ClientProfile')
      },
      {
        path: '/client/me/edit',
        name: 'Edit SRC Client Profile',
        meta: {permission: 'client_profile_update'},
        component: lazyLoad('clients/EditProfile')
      },
      {
        path: 'clients',
        name: 'SRC Client Management',
        redirect: '/clients',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/clients',
            name: 'SRC Clients',
            meta: {permission: 'client_view'},
            component: lazyLoad('clients/ClientIndex')
          },
          {
            path: '/client/new',
            name: 'Add New SRC Client',
            meta: {permission: 'client_create'},
            component: lazyLoad('clients/ClientCreate')
          },
          {
            path: '/client/:uuid/keys',
            name: 'SRC Client API Keys',
            meta: {permission: 'client_view'},
            component: lazyLoad('clients/ClientKey')
          },
          {
            path: '/client/:uuid/edit',
            name: 'Edit SRC Client',
            meta: {permission: 'client_update'},
            component: lazyLoad('clients/ClientEdit')
          },
          {
            path: '/client/:uuid',
            name: 'SRC Client Detail',
            meta: {permission: 'client_view'},
            component: lazyLoad('clients/ClientDetail')
          }
        ]
      },
      {
        path: 'bins',
        name: 'Card BIN Management',
        redirect: '/bins',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/bins',
            name: 'Card BINs',
            meta: {permission: 'bin_view'},
            component: lazyLoad('bins/BinIndex')
          },
          {
            path: '/bin/new',
            name: 'Add New Card BIN',
            meta: {permission: 'bin_create'},
            component: lazyLoad('bins/BinCreate')
          },
          {
            path: '/bin/:uuid',
            name: 'Card BIN Detail',
            meta: {permission: 'bin_view'},
            component: lazyLoad('bins/BinDetail')
          }
        ]
      },
      {
        path: 'users',
        name: 'Users',
        redirect: '/users',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/users',
            name: '',
            meta: {permission: 'access_view'},
            component: lazyLoad('users/UserIndex')
          },
          {
            path: '/user/new',
            name: 'Add New User',
            meta: {permission: 'access_create'},
            component: lazyLoad('users/UserCreate')
          },
          {
            path: '/user/:id/edit',
            name: 'Edit User',
            meta: {permission: 'access_update'},
            component: lazyLoad('users/UserEdit')
          },
          {
            path: '/user/:id',
            name: 'User Detail',
            meta: {permission: 'access_view'},
            component: lazyLoad('users/UserDetail')
          }
        ]
      },
      {
        path: 'roles',
        name: 'Roles',
        redirect: '/roles',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/roles',
            name: '',
            meta: {permission: 'access_view'},
            component: lazyLoad('roles/RoleIndex')
          },
          {
            path: '/role/new',
            name: 'Add New Role',
            meta: {permission: 'access_create'},
            component: lazyLoad('roles/RoleCreate')
          },
          {
            path: '/role/:id/edit',
            name: 'Edit Role',
            meta: {permission: 'access_update'},
            component: lazyLoad('roles/RoleEdit')
          },
          {
            path: '/role/:id',
            name: 'Role Detail',
            meta: {permission: 'access_view'},
            component: lazyLoad('roles/RoleDetail')
          }
        ]
      },
      {
        path: 'settings',
        name: 'General Settings',
        meta: {permission: 'system_view'},
        component: lazyLoad('systems/SystemSettings')
      },
      {
        path: 'consents',
        name: 'Legal Settings',
        redirect: '/consents',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/consents',
            name: 'Legal Consents',
            meta: {permission: 'legal_view'},
            component: lazyLoad('consents/ConsentIndex')
          },
          {
            path: '/consent/new',
            name: 'Add New Legal Consent',
            meta: {permission: 'legal_create'},
            component: lazyLoad('consents/ConsentCreate')
          },
          {
            path: '/consent/:id',
            name: 'Legal Consent Detail',
            meta: {permission: 'legal_view'},
            component: lazyLoad('consents/ConsentDetail')
          }
        ]
      },
      {
        path: 'data',
        name: 'Export Data',
        meta: {permission: 'data_export'},
        component: lazyLoad('data/DataExport')
      },
      {
        path: 'system-infra',
        name: 'System Infrastructure',
        meta: {permission: 'system_infra'},
        component: lazyLoad('systems/SystemInfra')
      },
      {
        path: '/queue/:id',
        name: 'Queue Messages',
        meta: {permission: 'system_infra'},
        component: lazyLoad('systems/QueueMessages')
      },
      {
        path: 'logs',
        name: 'Logs',
        redirect: '/audit-logs',
        meta: { permission: 'audit_view' },
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/audit-logs',
            name: 'Audit Logs',
            meta: { permission: 'audit_view' },
            component: lazyLoad('systems/AuditLogs')
          }
        ]
      },
      {
        path: 'task-history',
        name: 'Tasks',
        redirect: '/task-history',
        meta: {permission: ['task_history_view', 'task_history_view_all']},
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '/task-history',
            name: 'Task History',
            meta: {permission: ['task_history_view', 'task_history_view_all']},
            component: lazyLoad('systems/TaskHistory')
          },
          {
            path: '/task-suspend/:id',
            name: 'Request To Suspend',
            meta: {permission: ['task_history_view', 'task_history_view_all']},
            component: lazyLoad('tasks/TableView')
          },
          {
            path: '/task-delete/:id',
            name: 'Request To Delete',
            meta: {permission: ['task_history_view', 'task_history_view_all']},
            component: lazyLoad('tasks/TableView')
          },
          {
            path: '/task-reset/:id',
            name: 'Request To Reset',
            meta: {permission: ['task_history_view', 'task_history_view_all']},
            component: lazyLoad('tasks/TableView')
          },
          {
            path: '/task-reactivate/:id',
            name: 'Request To Reactive',
            meta: {permission: ['task_history_view', 'task_history_view_all']},
            component: lazyLoad('tasks/TableView')
          },
          {
            path: '/task-configure/:id',
            name: 'Request To Change',
            meta: {permission: ['task_history_view', 'task_history_view_all']},
            component: lazyLoad('tasks/TableView')
          },
          {
            path: '/task-api/:id',
            name: 'Request To Update API',
            meta: {permission: ['task_history_view', 'task_history_view_all']},
            component: lazyLoad('tasks/TableView')
          },
          {
            path: '/task-bin/:id',
            name: 'BIN Range Amendment',
            meta: {permission: ['task_history_view', 'task_history_view_all']},
            component: lazyLoad('tasks/BINView')
          },
          {
            path: '/task-create/:id',
            name: 'Request To Add',
            meta: {permission: ['task_history_view', 'task_history_view_all']},
            component: lazyLoad('tasks/CreateView')
          },
          {
            path: '/task-update/:id',
            name: 'Request To Update',
            meta: {permission: ['task_history_view', 'task_history_view_all']},
            component: lazyLoad('tasks/EditView')
          },
          {
            path: '/task-role/:id',
            name: 'Request To Update Role',
            meta: {permission: ['task_history_view', 'task_history_view_all']},
            component: lazyLoad('tasks/RoleView')
          }
        ]
      },
      {
        path: '/me',
        name: 'Account Detail',
        component: lazyLoad('users/AccountDetail')
      },
      {
        path: '/logout',
        name: 'Logout',
        component: lazyLoad('users/UserLogout')
      }
    ]
  },
  {
    path: '/collect/:id',
    name: 'Collect file',
    component: lazyLoad('data/DataCollect')
  },
  {
    path: '/login',
    name: 'Login',
    component: lazyLoad('LoginPage')
  },
  {
    path: '/oauth',
    name: 'Oauth',
    component: lazyLoad('OauthPage')
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: lazyLoad('ForgotPassword')
  },
  {
    path: '/reset-password/:id',
    name: 'Reset Password',
    component: lazyLoad('ResetPassword')
  },
  {
    path: '/401',
    name: 'Unauthorize',
    component: lazyLoad('errors/401')
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: lazyLoad('errors/403')
  },
  {
    path: '/500',
    name: 'Internal Server Error',
    component: lazyLoad('errors/500')
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: lazyLoad('errors/404')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = /(login|oauth|forgot-password|reset-password|collect|404|401|403|500|404)/
  const authRequired = !publicPages.test(to.path)
  const loggedIn = sessionStorage.getItem('user')

  if (authRequired && !loggedIn) {
    return next('/login')
  }
  let user =  JSON.parse(loggedIn);
  if(to.meta.permission != null){
    if(Array.isArray(to.meta.permission)) {
      let found = to.meta.permission.find(p => user.permissions.includes(p))

      if(!found) {
        return next('/403')
      }
    } else if(!user.permissions.includes(to.meta.permission)){
      return next('/403')
    }
  }

  next()
})

export default router
